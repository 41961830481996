import { FlightPlan } from 'ivaobr-api-client';
import { FunctionComponent } from 'react';

interface SimbriefDispatchInterface {
   airline?: string;
   fltnum?: number;
   type?: string;
   orig?: string;
   dest?: string;
   date?: string;
   deph?: string;
   depm?: string;
   route?: string;
   steh?: string;
   stem?: string;
   reg?: string;
   fin?: string;
   selcal?: string;
   pax?: string;
   altn?: string;
   fl?: number;
   cpt?: string;
   pid?: string;
   fuelfactor?: string;
   manualzfw?: number;
   addedfuel?: number;
   contpct?: number;
   resvrule?: number;
   taxiout?: number;
   taxiin?: number;
   cargo?: number;
   origrwy?: string;
   destrwy?: string;
   climb?: string;
   descent?: string;
   cruise?: string;
   civalue?: number;
   callsign?: string;
   manualrmk?: string;
   static_id?: string;
   acdata?: {
      cat?: string;
      equip?: string;
      transponder?: string;
      pbn?: string;
      extrarmk?: string;
      maxpax?: number;
      oew?: number;
      mzfw?: number;
      mtow?: number;
      mlw?: number;
      maxfuel?: number;
   };
   planformat?: string;
   units: 'KGS';
   navlog: 1;
   etops: 1;
   stepclimbs: 1;
   tlr: 1;
   notams: 1;
   firnot: 1;
   maps: 'detail';
}

const formatUrl = (flight: FlightPlan) => {
   const flightRequest: SimbriefDispatchInterface = {
      planformat: 'EZY',
      units: 'KGS',
      navlog: 1,
      etops: 1,
      stepclimbs: 1,
      tlr: 1,
      notams: 1,
      firnot: 1,
      maps: 'detail',
      airline: flight.callsign.substr(0, 3),
      fltnum: parseInt(flight.callsign.substr(3, 4)),
      type: flight.acft.substr(0, 4),
      orig: flight.dep,
      dest: flight.dest,
      deph: flight.eobt.substr(0, 2),
      depm: flight.eobt.substr(2, 2),
      contpct: 0.1,
      resvrule: 30,
      callsign: flight.callsign,
      route: flight.route.trim(),
   };

   const jsonFormated = {
      ...flightRequest,
   } as { [key: string]: any };

   console.log(jsonFormated);

   const url = Object.keys(jsonFormated)
      .map((key) => {
         return `${key}=${jsonFormated[key]}`;
      })
      .join('&');

   return encodeURI(`http://www.simbrief.com/system/dispatch.php?${url}`);
};

export interface SimbriefDispatchProps {
   flight: FlightPlan;
}

export const SimbriefDispatch: FunctionComponent<SimbriefDispatchProps> = ({ flight }) => {
   return (
      <button
         type="submit"
         className="bg-customGreen text-white w-24 rounded-xl font-medium"
         onClick={() => window.open(formatUrl(flight), '_newtab')}
      >
         Simbrief
      </button>
   );
};
