import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Context } from '../../context';
import Map from '../../icons/map.svg';
import './charts.css';

import { ReactComponent as Dark } from '../../icons/dark.svg';
import { ReactComponent as Light } from '../../icons/light.svg';
import { ReactComponent as Close } from '../../icons/close.svg';

import logo from '../../images/logo-round.png';
import { APIS } from '../../api';
import { Footer } from '../../components/footer/footer';
import { Loader } from '../../components/loader/loader';

interface ChargesProps {}

const rotations = ['rotate-0', 'rotate-90', 'rotate-180', '-rotate-90'];

export const ChartsPage: FunctionComponent<ChargesProps> = () => {
   const { airport, setAirport } = useContext(Context);
   const [chart, setChart] = useState<any>();
   const [filter, setFilter] = useState('');
   const [dark, setDark] = useState(true);
   const [ICAO, setICAO] = useState('');
   const [loading, setLoading] = useState(false);
   const [reqError, setReqError] = useState(false);
   const [count, setCount] = useState(0);
   const [viewMode, setViewMode] = useState(false);
   const [deviceCheck, setDeviceCheck] = useState(false);
   const [rotation, setRotation] = useState(0);
   const [chartLoading, setChartLoading] = useState(false);

   const classes = {
      ADC: 'bg-customYellow',
      SID: 'bg-customPink',
      STAR: 'bg-customCyan',
      IAC: 'bg-customRed',
      GMC: 'bg-customGreen',
      PDC: 'bg-customPurple',
      VAC: 'bg-customOrange',
   };

   const openChart = (chart: any) => {
      setChartLoading(true);
      setChart(false);
      setTimeout(() => {
         setChart(chart);
      }, 1000);
   };

   const getRotation = () => {
      return rotations[rotation];
   };

   const rotate = () => {
      if (rotation === rotations.length) {
         setRotation(0);
      } else {
         setRotation(rotation + 1);
      }
   };

   const fetchAirport = async () => {
      const { airportAPI } = APIS;

      setLoading(true);
      setReqError(false);

      try {
         const { data } = await airportAPI.airportsIcaoGet(ICAO);
         setAirport(data);
      } catch {
         setReqError(true);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      const interval = setInterval(() => {
         if (count >= 3) {
            setCount(0);
         } else {
            setCount(count + 1);
         }
      }, 500);

      if (!deviceCheck) {
         const isMobile = window.innerHeight < 768;
         setViewMode(!isMobile);
         setDeviceCheck(true);
      }

      return () => clearInterval(interval);
   }, [count, setCount, deviceCheck]);

   const getPoints = () => new Array(count).fill('.').join('');

   const chartsTypes = [
      {
         type: 'ADC',
         bg: 'bg-customYellow',
         border: 'border-customYellow',
      },
      {
         type: 'SID',
         bg: 'bg-customPink',
         border: 'border-customPink',
      },
      {
         type: 'STAR',
         bg: 'bg-customCyan',
         border: 'border-customCyan',
      },
      {
         type: 'IAC',
         bg: 'bg-customRed',
         border: 'border-customRed',
      },
      {
         type: 'GMC',
         bg: 'bg-customGreen',
         border: 'border-customGreen',
      },
      {
         type: 'PDC',
         bg: 'bg-customPurple',
         border: 'border-customPurple',
      },
      {
         type: 'VAC',
         bg: 'bg-customOrange',
         border: 'border-customOrange',
      },
   ];

   const getAirportChartTypes = (): Array<string> => {
      return airport?.charts.data.map((chart: any) => chart.type);
   };

   return (
      <div className="h-full w-full m-auto flex justify-center sm:justify-start overflow-hidden">
         {airport && (
            <div
               className={`w-screen sm:w-96 h-full overflow-y-hidden overflow-x-hidden bg-customGray-dark ${
                  airport && chart ? 'hidden sm:block' : ''
               }`}
            >
               <div className="charts-image-container">
                  <div className="charts-image" />
                  <div className="charts-image-info">
                     <div className="w-80 h-24 relative left-11 font-nunito not-italic font-extrabold text-5xl flex items-end text-customYellow">
                        {airport.icao}
                     </div>
                     <div className="w-80 sm:w-80 h-24 relative left-11 font-nunito not-italic font-light text-2xl flex items-start text-white">
                        {(airport as any).info.name}
                     </div>
                     <div className="w-72 sm:w-80 h-14 flex m-auto font-nunito not-italic font-semibold text-2xl items-center justify-center text-white">
                        <img src={Map} alt="" />
                        <div className="ml-6">Aeronautical Charts</div>
                     </div>
                     <hr className="w-72 sm:w-80 m-auto mb-2" />
                     <div className="w-screen sm:w-96 h-10 bg-customGray-dark flex justify-items-center">
                        <div className="flex content-center justify-around items-center w-96 m-auto">
                           {chartsTypes
                              .filter((type) => getAirportChartTypes().some((_type) => _type === type.type))
                              .map((type) => (
                                 <div
                                    className={`${type.bg} ${type.border} bg-opacity-30 flex justify-center w-12 h-6 font-nunito not-italic font-semibold text-base content-center items-center text-white rounded border-2 border-solid cursor-pointer`}
                                    onClick={() => setFilter(type.type)}
                                    key={type.type}
                                 >
                                    {type.type}
                                 </div>
                              ))}
                        </div>
                     </div>
                  </div>
               </div>
               <div className="w-96 m-auto flex justify-center overflow-y-auto overflow-x-hidden h-1/2 mt-4">
                  <div className="flex items-center flex-col w-80">
                     {airport.charts.data
                        .filter((_chart: any) => _chart.type === filter)
                        .map((_chart: any) => (
                           <div
                              className={`w-80 h-auto flex focus-within:sr-onlybg-opacity-50 rounded-lg mt-2 cursor-pointer items-stretch ${
                                 chart && chart.link === _chart.link ? (classes as any)[_chart.type] : 'bg-customGray'
                              } bg-opacity-30`}
                              onClick={() => openChart(_chart)}
                           >
                              <div className={`w-2 h-auto rounded-l-lg ${(classes as any)[filter]}`} />
                              <div className="font-nunito not-italic font-bold text-base flex items-center text-white ml-2">
                                 {_chart.name}
                              </div>
                           </div>
                        ))}
                  </div>
               </div>
            </div>
         )}

         {airport && chart && (
            <div className="h-full w-full sm:w-4/5 flex justify-center items-center">
               <div className={`flex flex-col justify-center charts-container`}>
                  {!chartLoading && (
                     <div className="h-6 w-full hidden sm:flex justify-center ">
                        <div
                           onClick={() => rotate()}
                           className="bg-opacity-30 border-customYellow flex justify-center w-16 h-6 font-nunito not-italic font-semibold text-base content-center items-center text-white rounded border-2 border-solid cursor-pointer"
                        >
                           Rotate
                        </div>
                     </div>
                  )}
                  <iframe
                     title={chart.name}
                     src={`https://docs.google.com/viewer?url=${chart.link}&amp&embedded=true`}
                     className={`mt-4 ${dark ? 'charts-dark' : ''} w-full h-full transform ${getRotation()}`}
                     onLoad={() => setChartLoading(false)}
                  />
               </div>

               {chartLoading && (
                  <div className="relative charts-loading">
                     <Loader />
                  </div>
               )}
            </div>
         )}

         {airport && chart && viewMode && (
            <div
               className="border-r-8 border-solid border-customYellow w-24 h-24 bg-customGray-dark rounded-l-xl absolute right-0 top-24 cursor-pointer"
               onClick={() => setViewMode(false)}
            >
               <div className="w-24 h-6 left-3 uppercase text-customGray-light font-nunito not-italic font-bold text-xs flex items-center justify-center underline">
                  view mode
               </div>

               <div
                  className="mt-2 m-auto bg-customYellow-dark bg-opacity-25 h-5 w-16 uppercase border border-solid border-customYellow rounded-xl cursor-pointer flex items-center"
                  onClick={() => setDark(false)}
               >
                  <i className="w-4 h-4">
                     <Light />
                  </i>
                  <div className="text-customYellow justify-around w-10 h-4 font-nunito font-bold text-xs flex items-center">light</div>
               </div>

               <div
                  className="mt-2 m-auto bg-customCyan-dark bg-opacity-25 h-5 w-16 uppercase border border-solid border-customCyan-dark rounded-xl cursor-pointer flex items-center"
                  onClick={() => setDark(true)}
               >
                  <i className="w-4 h-4">
                     <Dark />
                  </i>
                  <div className="text-customCyan-dark justify-around w-10 h-4 font-nunito font-bold text-xs flex items-center">dark</div>
               </div>
            </div>
         )}

         {airport && chart && !viewMode && (
            <div className="w-2 h-24 bg-customYellow absolute right-0 top-24 cursor-pointer" onClick={() => setViewMode(true)} />
         )}

         {!airport && (
            <div className="w-full flex flex-col">
               <div className="w-72 sm:w-96 h-96 sm:h-1/2 rounded-3xl bg-customGray m-auto flex justify-center items-center flex-col">
                  <div className="text-white text-2xl font-bold uppercase">Airport Charts</div>
                  <img src={logo} className="w-44 h-44" alt="IVAO BR Logo" />

                  {!loading && (
                     <>
                        <input
                           type="text"
                           placeholder="ICAO"
                           className="mt-3 w-48 sm:w-72 h-10 uppercase bg-white border-0 rounded-full font-poppins not-italic font-medium text-xl text-center text-gray-400"
                           maxLength={4}
                           value={ICAO}
                           onChange={(event) => {
                              setICAO(event.target.value);
                              setReqError(false);
                           }}
                        />
                        <button
                           className="w-48 h-10 sm:w-72 mt-10 bg-customGreen rounded-full border-none font-poppins not-italic font-semibold text-2xl leading-9 uppercase bg-green text-white"
                           onClick={() => fetchAirport()}
                        >
                           Search
                        </button>
                     </>
                  )}
                  {loading && (
                     <div className="w-96 h-24 font-nunito not-italic font-semibold text-3xl leading-9 flex items-center justify-center text-center text-white">
                        LOADING{getPoints()}
                     </div>
                  )}
                  {reqError && <h5 className="text-white mt-5">Failed to fetch charts from {ICAO.toUpperCase()} airport</h5>}
               </div>

               <div className="mb-1">
                  <Footer />
               </div>
            </div>
         )}

         {airport && (
            <div className="h-4 w-4 absolute top-16 right-2 sm:top-2 sm:right-2 fill-current text-black cursor-pointer">
               <i onClick={() => (chart && setChart(undefined)) || (!chart && setAirport(undefined))}>
                  <Close />
               </i>
            </div>
         )}
      </div>
   );
};
