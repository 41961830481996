import { LatLng } from 'leaflet';
import React, { useContext, useState } from 'react';
import { Circle, MapContainer, Popup, TileLayer } from 'react-leaflet';
import { Context } from '../context';
import { APIS } from '../api';
import 'leaflet/dist/leaflet.css';
import { Page } from '../pages';

interface METARs {
   [icao: string]: string;
}

interface TAFs {
   [icao: string]: string;
}

interface AirportName {
   [icao: string]: string;
}

export const MapPage = () => {
   const { airports, setActualPage, setAirport, airport } = useContext(Context);

   const [TAFs, setTAFs] = useState<TAFs>({});
   const [METARs, setMETARs] = useState<METARs>({});
   const [airportName, setAirportName] = useState<AirportName>({});

   const [loading, setLoading] = useState(false);

   const colors = {
      VFR: 'green',
      SVFR: 'yellow',
      IFR: 'red',
   };

   const { airportAPI } = APIS;

   const mapCenter = new LatLng(-15.8647222222, -47.9180555556);

   const loadMetar = async (icao: string) => {
      const metar = METARs[icao];

      if (!metar) {
         setLoading(true);
         try {
            const { data } = await airportAPI.airportsIcaoGet(icao);
            setTAFs({ ...TAFs, [icao]: data.weather.taf });
            setMETARs({ ...METARs, [icao]: data.weather.metar });
            setAirportName({
               ...airportName,
               [icao]: icao + ' - ' + (data as any).info.name.toUpperCase() + ' - ' + data.weather.status,
            });
            setAirport(data);
         } finally {
            setLoading(false);
         }
      }
   };

   const getAirport = async (icao: string) => {
      try {
         if (airport && airport.icao !== icao) {
            const { data } = await airportAPI.airportsIcaoGet(icao);
            setAirport(data);
         }

         setActualPage(Page.AIRPORT);
      } finally {
      }
   };

   return (
      <MapContainer center={mapCenter} zoom={6} scrollWheelZoom={true} className="h-full w-full m-auto">
         {/*<TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"
         />*/}

         <TileLayer
            url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
            attribution='&copy;
                    <a href="http://osm.org/copyright">OpenStreetMap</a>contributors'
         />

         {airports.map((airport) => (
            <Circle key={airport.icao} center={new LatLng(airport.lat, airport.lon)} radius={15000} color={colors[airport.status]}>
               <Popup onOpen={() => loadMetar(airport.icao)}>
                  {!loading && (
                     <>
                        <p>
                           <b>{airportName[airport.icao]}</b>
                        </p>
                        <p>{METARs[airport.icao]}</p>
                        <p>{TAFs[airport.icao]}</p>
                     </>
                  )}

                  {loading && <p>Loading...</p>}

                  {airportName[airport.icao] && (
                     <p style={{ cursor: 'pointer', fontWeight: 700 }} onClick={() => getAirport(airport.icao)}>
                        Ver mais sobre {airport.icao}
                     </p>
                  )}
               </Popup>
            </Circle>
         ))}
      </MapContainer>
   );
};
export default MapPage;
