import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Context } from '../../context';
import Weather from '../../icons/weather.svg';
import Freq from '../../icons/freq.svg';
import { ReactComponent as Paper } from '../../icons/paper.svg';
import './airport.css';
import { Page } from '../../pages';

import logo from '../../images/logo-round.png';
import { APIS } from '../../api';
import { ReactComponent as Close } from '../../icons/close.svg';
import { Footer } from '../../components/footer/footer';

interface AirportProps {}

export const AirportPage: FunctionComponent<AirportProps> = () => {
   const { airport, setActualPage, setAirport } = useContext(Context);
   const [ICAO, setICAO] = useState('');
   const [loading, setLoading] = useState(false);
   const [reqError, setReqError] = useState(false);
   const [count, setCount] = useState(0);

   const del = airport && (airport as any).info.atcs.find((atc: any) => (atc.connectionCallsign as string).includes('_DEL'));
   const gnd = airport && (airport as any).info.atcs.find((atc: any) => (atc.connectionCallsign as string).includes('_GND'));
   const twr = airport && (airport as any).info.atcs.find((atc: any) => (atc.connectionCallsign as string).includes('_TWR'));

   const fetchAirport = async () => {
      const { airportAPI } = APIS;

      setLoading(true);
      setReqError(false);

      try {
         const { data } = await airportAPI.airportsIcaoGet(ICAO);
         setAirport(data);
      } catch {
         setReqError(true);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      const interval = setInterval(() => {
         if (count >= 3) {
            setCount(0);
         } else {
            setCount(count + 1);
         }
      }, 500);

      return () => clearInterval(interval);
   }, [count, setCount]);

   const getPoints = () => new Array(count).fill('.').join('');

   return (
      <div className="h-full w-full m-auto flex flex-col">
         {airport && (
            <div className="w-full sm:w-96 h-full overflow-y-hidden overflow-x-hidden bg-customGray-dark">
               <div>
                  <div className="airport-image" />
                  <div className="airport-image-info">
                     <div className="w-80 h-24 relative left-11 font-nunito not-italic font-extrabold text-5xl flex items-end text-customYellow">
                        {airport.icao}
                     </div>
                     <div className="w-80 sm:w-80 h-24 relative left-11 font-nunito not-italic font-light text-2xl flex items-start text-white">
                        {(airport as any).info.name}
                     </div>

                     <div className="w-72 sm:w-80 h-14 flex m-auto font-nunito not-italic font-semibold text-2xl items-center justify-center text-white">
                        <img src={Weather} alt="" />
                        <div className="ml-6">Weather Info</div>
                     </div>

                     <hr className="w-72 sm:w-80 m-auto mb-2"></hr>

                     <div className="w-28 h-6 relative left-11 font-nunito not-italic font-bold text-base flex items-center text-metar">
                        METAR:
                     </div>

                     <div className="w-72 sm:w-80 h-12 m-auto bg-metar-bg bg-opacity-30 rounded-xl flex justify-center items-center">
                        <div className="w-72 font-roboto not-italic font-normal text-sm flex items-center text-white">
                           {airport.weather.metar?.replace('METAR', '')}
                        </div>
                     </div>

                     <div className="w-28 h-6 relative left-11 mt-11 font-nunito not-italic font-bold text-base flex items-center text-taf">
                        TAF:
                     </div>

                     <div className="w-72 sm:w-80 m-auto bg-taf-bg bg-opacity-30 round-xl flex justify-center items-center">
                        <div className="w-72 font-roboto not-italic font-normal text-sm flex items-center text-white">
                           {airport.weather.taf?.replace('TAF', '')}
                        </div>
                     </div>

                     <div className="w-72 sm:w-80 h-14 flex m-auto font-nunito not-italic font-semibold text-2xl items-center justify-center text-white">
                        <img src={Freq} alt="" />
                        <div className="ml-6">Frequencies</div>
                     </div>

                     <hr className="w-72 sm:w-80 m-auto mb-2"></hr>

                     <div className="w-72 sm:w-80 m-auto h-36 flex flex-row flex-wrap justify-between">
                        {del && (
                           <div className="w-32 mt-1">
                              <div className="w-32 h-4 font-roboto not-italic font-bold text-xs flex justify-center text-freq">
                                 {del.connectionCallsign}
                              </div>

                              <div className="w-32 h-9 bg-freq-bg rounded-xl">
                                 <div className="w-32 h-9 font-roboto not-italic font-normal text-xs flex items-center justify-center text-center text-white">
                                    {del.frequencies.join(' ')}
                                 </div>
                              </div>
                           </div>
                        )}

                        {gnd && (
                           <div className="w-32 mt-1">
                              <div className="w-32 h-4 font-roboto not-italic font-bold text-xs flex justify-center text-freq">
                                 {gnd.connectionCallsign}
                              </div>

                              <div className="w-32 h-9 bg-freq-bg rounded-xl">
                                 <div className="w-32 h-9 font-roboto not-italic font-normal text-xs flex items-center justify-center text-center text-white">
                                    {gnd.frequencies.join(' ')}
                                 </div>
                              </div>
                           </div>
                        )}

                        {twr && (
                           <div className="w-32 mt-1">
                              <div className="w-32 h-4 font-roboto not-italic font-bold text-xs flex justify-center text-freq">
                                 {twr.connectionCallsign}
                              </div>

                              <div className="w-32 h-9 bg-freq-bg rounded-xl">
                                 <div className="w-32 h-9 font-roboto not-italic font-normal text-xs flex items-center justify-center text-center text-white">
                                    {twr.frequencies.join(' ')}
                                 </div>
                              </div>
                           </div>
                        )}
                     </div>

                     <hr className="w-full"></hr>

                     <div className="w-72 sm:w-80 h-40 m-auto flex justify-center items-center">
                        <button
                           className="w-60 h-14 bg-customYellow border-0 rounded-2xl flex justify-center items-center"
                           onClick={() => {
                              setActualPage(Page.CHARTS);
                           }}
                        >
                           <i className="ml-5 fill-current text-white">
                              <Paper />
                           </i>

                           <div className="w-44 uppercase font-nunito not-italic font-bold text-lg flex items-center justify-center text-white">
                              Open Charts
                           </div>
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         )}

         {!airport && (
            <div className="w-72 sm:w-96 h-96 sm:h-1/2 rounded-3xl bg-customGray m-auto flex justify-center items-center flex-col">
               <div className="text-white text-2xl font-bold uppercase">Airport Info</div>
               <img src={logo} className="w-44" alt="IVAO BR Logo" />

               {!loading && (
                  <>
                     <input
                        type="text"
                        placeholder="ICAO"
                        className="mt-3 w-48 sm:w-72 h-10 uppercase bg-white border-0 rounded-full font-poppins not-italic font-medium text-xl text-center text-gray-400"
                        maxLength={4}
                        value={ICAO}
                        onChange={(event) => {
                           setICAO(event.target.value);
                           setReqError(false);
                        }}
                     />
                     <button
                        className="w-48 h-10 sm:w-72 mt-10 bg-customGreen rounded-full border-none font-poppins not-italic font-semibold text-2xl leading-9 uppercase bg-green text-white"
                        onClick={() => fetchAirport()}
                     >
                        Search
                     </button>
                  </>
               )}
               {loading && (
                  <div className="w-96 h-24 font-nunito not-italic font-semibold text-3xl leading-9 flex items-center justify-center text-center text-white">
                     LOADING{getPoints()}
                  </div>
               )}
               {reqError && <h5 className="text-white mt-5">Failed to fetch charts from {ICAO.toUpperCase()} airport</h5>}
            </div>
         )}

         {airport && (
            <div className="absolute right-2 top-16 sm:right-2 sm:top-2 fill-current text-black w-4 h-4 sm:w-4 sm:h-4 cursor-pointer">
               <i onClick={() => setAirport(undefined)}>
                  <Close />
               </i>
            </div>
         )}

         {!airport && (
            <div className="mb-1">
               <Footer />
            </div>
         )}
      </div>
   );
};
