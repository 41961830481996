import React, { FunctionComponent, useContext } from 'react';
import { Footer } from '../../components/footer/footer';
import { Context } from '../../context';
import { Page } from '../../pages';

export const PrivacyPage: FunctionComponent = () => {
   const { setActualPage } = useContext(Context);

   const policy = (result: boolean) => {
      localStorage.setItem('privacyAccepted', String(result));
      setActualPage(Page.MAP);
   };

   return (
      <div className="w-full h-screen flex flex-col justify-around m-00">
         <div className="flex justify-center items-center flex-col flex-wrap h-full">
            <div className="w-96 h-12 font-nunito not-italic font-semibold text-3xl leading-9 flex items-center justify-center text-center text-white">
               Nós usamos cookies
            </div>
            <div className="w-auto h-auto font-nunito not-italic text-base leading-9 flex items-center justify-center text-center text-white">
               Nós usamos cookies e outras tecnologias de monitoramento para melhorar sua experiência em nossa aplicação, para
               disponibilizar conteúdo personalizado, analisar o tráfego da nossa aplicação e entender o comportamento dos nossos usuários
            </div>

            <div className="w-60 flex justify-around m-4">
               <button type="submit" className="bg-customGreen text-white w-24 rounded-xl font-medium" onClick={() => policy(true)}>
                  Aceitar
               </button>

               <button type="submit" className="bg-customRed text-white w-24 rounded-xl font-medium" onClick={() => policy(false)}>
                  Recusar
               </button>
            </div>
         </div>

         <div className="mb-1">
            <Footer />
         </div>
      </div>
   );
};
