import { CSSProperties } from 'react';

type IStyle = { [key: string]: CSSProperties };

const styles: IStyle = {
   text: {
      fontSize: '8px',
   },
};

export const Footer = () => {
   return (
      <div>
         <div className="font-nunito not-italic text-white text-center" style={styles.text}>
            For simulation use <b>only</b>
         </div>
         <div className="font-nunito not-italic text-white text-center" style={styles.text}>
            IVAO Brazil - {new Date().getFullYear()}
         </div>
         <div className="font-nunito not-italic text-white text-center" style={styles.text}>
            Designed by{' '}
            <a target="_blank" href="https://www.ivao.aero/Member.aspx?Id=595866" rel="noreferrer">
               Ruy Monteiro
            </a>
         </div>
      </div>
   );
};
