import React from 'react';
import ReactDOM from 'react-dom';
import { ContextProvider } from './context';
import { App } from './app';
import './index.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
   <React.StrictMode>
      <ContextProvider>
         <App />
      </ContextProvider>
   </React.StrictMode>,
   document.getElementById('root')
);

serviceWorker.register();
