import { FlightPlan } from 'ivaobr-api-client';
import { FunctionComponent, useEffect, useState } from 'react';
import { APIS } from '../../api';
import { Footer } from '../../components/footer/footer';
import { SimbriefDispatch } from '../../components/simbrief/simbrief.dispatch';
import logo from '../../images/logo-round.png';

interface FlightPlanProps {}

export const FlightPlanPage: FunctionComponent<FlightPlanProps> = () => {
   const [dep, setDep] = useState('');
   const [arr, setArr] = useState('');
   const [fpl, setFpl] = useState<Array<FlightPlan>>([]);

   const [errorReq, setErrorReq] = useState(false);
   const [loading, setLoading] = useState(false);

   const [count, setCount] = useState(0);

   const [errorMessage, setErrorMessage] = useState('');

   const { routesAPI } = APIS;

   const fetchFpl = async () => {
      const _errorDep = dep.length !== 4;
      const _errorArr = arr.length !== 4;

      if (!_errorArr && !_errorDep) {
         setLoading(true);

         try {
            const { data: fpl } = await routesAPI.routesDepDestGet(dep, arr);
            setFpl(fpl);
            if (fpl.length === 0) {
               setErrorReq(true);
               setErrorMessage('No real flight plan founded for this route...');
            }
         } catch {
            setErrorReq(true);
            setErrorMessage('Error! :( Try later...');
         } finally {
            setLoading(false);
         }
      }
   };

   useEffect(() => {
      const interval = setInterval(() => {
         if (count >= 3) {
            setCount(0);
         } else {
            setCount(count + 1);
         }
      }, 500);

      return () => clearInterval(interval);
   }, [count, setCount]);

   const getPoints = () => new Array(count).fill('.').join('');

   return (
      <div className="h-full flex flex-col justify-center m-0">
         {fpl.length === 0 && (
            <div className="w-72 sm:w-96 h-96 sm:h-1/2 rounded-3xl m-auto flex justify-center items-center flex-col bg-customGray">
               {!loading && (
                  <>
                     <div className="text-white text-2xl font-bold uppercase">Real Flight Plans</div>
                     <img src={logo} alt="IVAO BR Logo" className="w-44 h-44" />
                     <input
                        type="text"
                        placeholder="Departure"
                        className="mt-3 w-48 sm:w-72 h-10 uppercase bg-white border-0 rounded-full font-poppins not-italic font-medium text-xl text-center text-gray-400"
                        maxLength={4}
                        onChange={(event) => setDep(event.target.value.toUpperCase())}
                        value={dep}
                     />
                     <input
                        type="text"
                        placeholder="Arrival"
                        className="mt-3 w-48 sm:w-72 h-10 uppercase bg-white border-0 rounded-full font-poppins not-italic font-medium text-xl text-center text-gray-400"
                        maxLength={4}
                        onChange={(event) => setArr(event.target.value.toUpperCase())}
                        value={arr}
                     />
                     <button
                        className="w-48 sm:w-72 mt-10 bg-customGreen rounded-full border-none font-poppins not-italic font-semibold text-2xl leading-9 uppercase bg-green text-white"
                        onClick={fetchFpl}
                     >
                        Search
                     </button>

                     {errorReq && <div style={{ marginTop: '20px', color: 'white' }}>{errorMessage}</div>}
                  </>
               )}

               {loading && (
                  <div className="w-96 h-24 font-nunito not-italic font-semibold text-3xl leading-9 flex items-center justify-center text-center text-white">
                     LOADING{getPoints()}
                  </div>
               )}
            </div>
         )}

         {!loading && fpl.length !== 0 && (
            <div className="flex justify-center items-center overflow-x-auto">
               <div className="rounded-2xl overflow-x-hidden w-11/12 h-4/5">
                  <table className="w-full">
                     <thead className="text-center font-nunito bg-table-header-bg text-table-header-text leading-6 uppercase text-xl">
                        <tr className="leading-10">
                           <th>callsign</th>
                           <th>aircraft</th>
                           <th>eobt</th>
                           <th>departure</th>
                           <th>route</th>
                           <th>destination</th>
                           <th>eet</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody className="bg-table-body-bg text-table-body-text font-nunito text-center">
                        {fpl.map((fpl) => (
                           <tr key={fpl.days + fpl.callsign + fpl.eobt} className="leading-10">
                              <td>{fpl.callsign}</td>
                              <td>{fpl.acft}</td>
                              <td>{fpl.eobt}</td>
                              <td>{fpl.dep}</td>
                              <td>{fpl.route}</td>
                              <td>{fpl.dest}</td>
                              <td>{fpl.eet}</td>
                              <td width="240px">
                                 <div className="flex justify-around">
                                    <SimbriefDispatch flight={fpl} />

                                    <form action="https://fpl.ivao.aero/api/fp/load" method="POST" target="_blank">
                                       <input type="hidden" name="CALLSIGN" value={fpl.callsign} />
                                       <input type="hidden" name="RULES" value="I" />
                                       <input type="hidden" name="FLIGHTTYPE" value="S" />
                                       <input type="hidden" name="NUMBER" value="1" />
                                       <input type="hidden" name="ACTYPE" value={(fpl.acft as string).substr(0, 4)} />
                                       <input type="hidden" name="WAKECAT" value={(fpl.acft as string).substr(5, 1)} />
                                       <input
                                          type="hidden"
                                          name="EQUIPMENT"
                                          value={(fpl.rmk as string).replace(
                                             new RegExp('(EQPT/[A-Z0-9]{1,})(/[A-Z0-9]{1,})([ ])(.{1,})'),
                                             (...args) => args[1].replace('EQPT/', '')
                                          )}
                                       />
                                       <input
                                          type="hidden"
                                          name="TRANSPONDER"
                                          value={(fpl.rmk as string).replace(
                                             new RegExp('(EQPT/[A-Z0-9]{1,})(/[A-Z0-9]{1,})([ ])(.{1,})'),
                                             (...args) => args[2].replace('/', '')
                                          )}
                                       />
                                       <input type="hidden" name="DEPICAO" value={fpl.dep} />
                                       <input
                                          type="hidden"
                                          name="DEPTIME"
                                          value={
                                             new Date(Date.now() + 45 * 60 * 1000).getUTCHours().toString().padStart(2, '0') +
                                             new Date(Date.now() + 45 * 60 * 1000).getUTCMinutes().toString().padStart(2, '0')
                                          }
                                       />
                                       <input type="hidden" name="SPEEDTYPE" value={(fpl.spd as string).substr(0, 1)} />
                                       <input type="hidden" name="SPEED" value={(fpl.spd as string).substr(1, 4)} />
                                       <input type="hidden" name="LEVELTYPE" value="F" />
                                       <input type="hidden" name="LEVEL" value={fpl.fl} />
                                       <input type="hidden" name="ROUTE" value={fpl.route} />
                                       <input type="hidden" name="DESTICAO" value={fpl.dest} />
                                       <input type="hidden" name="EET" value={fpl.eet} />
                                       <input
                                          type="hidden"
                                          name="OTHER"
                                          value={
                                             fpl.rmk +
                                             ` DOF/${
                                                new Date().getUTCFullYear().toString().substr(2, 2) +
                                                (new Date().getUTCMonth() + 1).toString().padStart(2, '0') +
                                                new Date().getUTCDate().toString().padStart(2, '0')
                                             }`
                                          }
                                       />
                                       <button type="submit" className="bg-customGreen text-white w-24 rounded-xl font-medium">
                                          IVAO
                                       </button>
                                    </form>
                                 </div>
                              </td>
                           </tr>
                        ))}
                     </tbody>
                  </table>
               </div>
            </div>
         )}

         {fpl.length === 0 && (
            <div className="mb-1">
               <Footer />
            </div>
         )}
      </div>
   );
};
