import { AirportStatus, Airport } from 'ivaobr-api-client';
import { createContext, FunctionComponent, useState } from 'react';
import { Page } from './pages';

interface ContextInterface {
   actualPage: Page;
   airports: Array<AirportStatus>;
   airport: Airport | undefined;
   isAuthed: boolean;
   setActualPage: (data: Page) => void;
   setAirports: (data: Array<AirportStatus>) => void;
   setAirport: (data: Airport | undefined) => void;
   setIsAuthed: (data: boolean) => void;
}

interface StateInterface {
   actualPage: Page;
   airports: Array<AirportStatus>;
   airport: Airport | undefined;
   isAuthed: boolean;
}

export const Context = createContext<ContextInterface>({
   actualPage: Page.MAP,
   airports: [],
   airport: { charts: '', icao: '', weather: '' },
   isAuthed: false,
   setActualPage: () => {},
   setAirports: () => {},
   setAirport: () => {},
   setIsAuthed: () => {},
});

const defaultState: StateInterface = {
   actualPage: Page.MAP,
   airport: undefined,
   airports: [],
   isAuthed: false,
};

export const ContextProvider: FunctionComponent = ({ children }) => {
   const [actualPage, setActualPage] = useState<Page>(defaultState.actualPage);
   const [airports, setAirports] = useState<Array<AirportStatus>>(defaultState.airports);
   const [airport, setAirport] = useState<Airport | undefined>();
   const [isAuthed, setIsAuthed] = useState(defaultState.isAuthed);

   const provideValue: ContextInterface = {
      actualPage,
      airports,
      airport,
      isAuthed,
      setAirport,
      setAirports,
      setActualPage,
      setIsAuthed,
   };

   return <Context.Provider value={provideValue}>{children}</Context.Provider>;
};
