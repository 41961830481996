import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { APIS } from './api';
import Navbar from './components/navbar/navbar';
import { Context } from './context';
import { Page } from './pages';
import { AirportPage } from './pages/airports/airport.page';
import { ChartsPage } from './pages/charts/charts.page';
import { FlightPlanPage } from './pages/fpl/fpl.page';
import { LoadingPage } from './pages/loading/loading.page';
import MapPage from './pages/map.page';
import { PrivacyPage } from './pages/privacy/privacy.page';

interface AppProps {}

interface AppParams {
   IVAOTOKEN?: string;
}

function useQuery<T>(): T {
   const search = document.location.search.replace('?', '');
   const params = search.split('&');
   const data = params.map((param) => param.split('=')).reduce((acc, val) => ({ ...acc, [val[0]]: val[1] }), {});
   return JSON.parse(JSON.stringify(data));
}

export const App: FunctionComponent<AppProps> = () => {
   const { IVAOTOKEN } = useQuery<AppParams>();
   const { actualPage, setAirports, isAuthed, setIsAuthed, setActualPage } = useContext(Context);
   const [loading, setLoading] = useState(true);

   const { airportAPI, authAPI } = APIS;
   const [policy, setPolicy] = useState(localStorage.getItem('privacyAccepted') === 'true');

   const checkIfIsAuthed = useCallback(() => {
      return new Promise<void>((resolve, reject) => {
         const jwt = localStorage.getItem('jwt');

         if (!jwt) {
            reject();
         } else {
            authAPI
               .authGet({ headers: { Authorization: `Bearer ${jwt}` } })
               .then(() => resolve())
               .catch(() => reject());
         }
      });
   }, [authAPI]);

   const FetchData = useCallback(async () => {
      return airportAPI.airportsStatusGet().then((response) => {
         setAirports(response.data);
         if (loading) {
            setLoading(false);
         }
         setTimeout(() => FetchData(), 5 * 60 * 1000);
      });
   }, [airportAPI, setAirports, loading]);

   const doAuth = useCallback(
      async (ivaoToken: string) => {
         return authAPI.authPost({ ivaoToken }).then((response) => {
            localStorage.setItem('jwt', response.data.token);
         });
      },
      [authAPI]
   );

   useEffect(() => {
      if (!localStorage.getItem('privacyAccepted')) {
         setActualPage(Page.PRIVACY);
      }

      if (!IVAOTOKEN && !isAuthed) {
         checkIfIsAuthed()
            .then(() => {
               setIsAuthed(true);
               FetchData();
            })
            .catch(() => {
               const newUrl = `https://login.ivao.aero/index.php?url=${document.location.href}`;
               window.location.href = newUrl;
            });
      } else if (IVAOTOKEN) {
         doAuth(IVAOTOKEN).then(() => {
            document.location.href = '/';
         });
      }
   }, [FetchData, IVAOTOKEN, doAuth, checkIfIsAuthed, isAuthed, setIsAuthed, setActualPage]);

   useEffect(() => {
      setPolicy(localStorage.getItem('privacyAccepted') === 'true');
   }, [actualPage]);

   useEffect(() => {
      console.log();
   }, [policy]);

   const renderPage = (page: Page) => {
      const pages = {
         [Page.MAP]: <MapPage />,
         [Page.FPL]: <FlightPlanPage />,
         [Page.AIRPORT]: <AirportPage />,
         [Page.CHARTS]: <ChartsPage />,
         [Page.PRIVACY]: <PrivacyPage />,
      };

      return pages[page];
   };

   return (
      <>
         {((!loading && isAuthed) || actualPage === Page.PRIVACY) && (
            <>
               <div className="flex flex-col sm:flex-row w-screen h-screen">
                  {policy && (
                     <Helmet>
                        <script async src="https://www.googletagmanager.com/gtag/js?id=G-K3J7FMSD24"></script>
                        <script>
                           {`  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-K3J7FMSD24');`}
                        </script>
                     </Helmet>
                  )}
                  {actualPage !== Page.PRIVACY && <Navbar />}
                  <div className="app w-screen h-full sm:h-screen sm:w-full">{renderPage(actualPage)}</div>
               </div>
            </>
         )}

         {(loading || !isAuthed) && localStorage.getItem('privacyAccepted') && <LoadingPage />}
      </>
   );
};
