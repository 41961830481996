import React, { FunctionComponent, useEffect, useState } from 'react';
import { Footer } from '../../components/footer/footer';
import logo from '../../images/logo.png';

interface LoadingProps {}

export const LoadingPage: FunctionComponent<LoadingProps> = () => {
   const [count, setCount] = useState(0);

   useEffect(() => {
      const interval = setInterval(() => {
         if (count >= 3) {
            setCount(0);
         } else {
            setCount(count + 1);
         }
      }, 500);

      return () => clearInterval(interval);
   }, [count, setCount]);

   const getPoints = () => new Array(count).fill('.').join('');

   return (
      <div className="w-full h-screen flex flex-col justify-around m-00">
         <div className="flex justify-center items-center flex-col flex-wrap h-full">
            <img src={logo} className="w-4/5 sm:w-2/5" alt="IVAO BR Logo" />

            <div className="w-96 h-24 font-nunito not-italic font-semibold text-3xl leading-9 flex items-center justify-center text-center text-white">
               LOADING{getPoints()}
            </div>
         </div>

         <div className="mb-1">
            <Footer />
         </div>
      </div>
   );
};
