import React, { useContext } from 'react';
import logo from '../../images/logo-round.png';
import { ReactComponent as Document } from '../../icons/document.svg';
import { ReactComponent as FPL } from '../../icons/fpl.svg';
import { ReactComponent as Tower } from '../../icons/twr.svg';
import { ReactComponent as Globe } from '../../icons/globe.svg';
import { Context } from '../../context';
import { Page } from '../../pages';

function Navbar() {
   const { setActualPage, actualPage } = useContext(Context);

   const activeColor = '#FFFFFF';
   const inactiveColor = '#5D5D5D';

   const getColor = (index: number) => {
      const pages = [Page.MAP, Page.CHARTS, Page.FPL, Page.AIRPORT];
      return pages[index] === actualPage ? activeColor : inactiveColor;
   };

   return (
      <ul className="list-none m-0 p-0 overflow-hidden sm:h-auto w-auto flex sm:flex-col justify-center h-16 sm:w-16 border-b-4 sm:border-b-0 sm:border-r-4 border-customYellow">
         <li className="float-left sm:float-none sm:h-16 w-16 hidden sm:block sm:pr-1">
            <a href="/">
               <img src={logo} alt="IVAO Logo" className="w-16 mt-2" />
            </a>
         </li>

         <ul className="w-full sm:h-full flex sm:flex-col justify-around items-center">
            <div className="sm:h-96 sm:w-auto w-96 flex sm:flex-col justify-around items-center">
               <li className="float-left sm:float-none cursor-pointer">
                  <i
                     style={{ fill: getColor(0) }}
                     onClick={() => {
                        setActualPage(Page.MAP);
                     }}
                  >
                     <Globe />
                  </i>
               </li>
               <li className="float-left sm:float-none cursor-pointer">
                  <i
                     style={{ fill: getColor(1) }}
                     onClick={() => {
                        setActualPage(Page.CHARTS);
                     }}
                  >
                     <Document />
                  </i>
               </li>
               <li className="float-left sm:float-none cursor-pointer">
                  <i
                     style={{ fill: getColor(2) }}
                     onClick={() => {
                        setActualPage(Page.FPL);
                     }}
                  >
                     <FPL />
                  </i>
               </li>
               <li className="float-left sm:float-none cursor-pointer">
                  <i
                     style={{ fill: getColor(3) }}
                     onClick={() => {
                        setActualPage(Page.AIRPORT);
                     }}
                  >
                     <Tower />
                  </i>
               </li>
            </div>
         </ul>
      </ul>
   );
}

export default Navbar;
